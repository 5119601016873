body {
  margin: 0;
  font-family: general-sans, 
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* fix React: ResizeObserver loop completed with undelivered notifications */
  #webpack-dev-server-client-overlay {
    display: none !important;
  };
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: #3366ff;
}

a:hover {
  color: #3366ff;
}

@font-face {
  font-family: 'general-sans';
  src: local('GeneralSans-Extralight'),
    url('./assets/fonts/GeneralSans-Extralight.otf');
  font-weight: 100;
}

@font-face {
  font-family: 'general-sans';
  src: local('GeneralSans-Extralight'),
    url('./assets/fonts/GeneralSans-Extralight.otf');
  font-weight: 200;
}

@font-face {
  font-family: 'general-sans';
  src: local('GeneralSans-Light'),
    url('./assets/fonts/GeneralSans-Light.otf');
  font-weight: 300;
}

@font-face {
  font-family: 'general-sans';
  src: local('GeneralSans-Regular'),
    url('./assets/fonts/GeneralSans-Regular.otf');
  font-weight: 400;
}

@font-face {
  font-family: 'general-sans';
  src: local('GeneralSans-Medium'),
    url('./assets/fonts/GeneralSans-Medium.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'general-sans';
  src: local('GeneralSans-Semibold'),
    url('./assets/fonts/GeneralSans-Semibold.otf');
  font-weight: 600;
}

@font-face {
  font-family: 'general-sans';
  src: local('GeneralSans-Bold'),
    url('./assets/fonts/GeneralSans-Bold.otf');
  font-weight: 700;
}
